import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./Solutions.module.scss"

const Solutions = ({ strapiData, columnSolution }) => {
  return (
    <div
      className={`${styles.Challenges} ${
        columnSolution ? styles.columnSolutionPad : ""
      }`}
    >
      <Container>
        <div
          className={`${styles.hire} ${
            columnSolution ? styles.columnSolutionBg : ""
          }`}
        >
          <h2
            className={`${styles.hireHeading} `}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />

          <div className={styles.cards}>
            {strapiData?.cards &&
              strapiData?.cards?.map((item, index) => (
                <div className={styles.talent} key={index}>
                  <img
                    className={styles.tick}
                    src="https://invozone-backend.s3.us-east-1.amazonaws.com/Vector_32dc188c32.png"
                    alt={item?.title}
                    decoding="async"
                    loading="lazy"
                    style={{ height: "26px", width: "26px" }}
                  />
                  <div className={styles.text}>
                    <p>{item?.title}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Solutions
