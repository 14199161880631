import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import * as styles from "./Cta.module.scss"

const Cta = ({ strapiData, globalCta, ctaColumn }) => {
  return (
    <div className={styles.cta}>
      <Container className={styles.pad}>
        <div className={styles.readyHuntBg}>
          <Col lg={12} md={12} xs={12} sm={12}>
            <h2
              className={`${styles.heading} ${
                globalCta ? styles.globalCtaHead : ""
              } ${ctaColumn ? styles.ctaColumnHead : ""}`}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
            <div className={`${styles.bannerBtn}`}>
              {strapiData?.buttons[0] && (
                <Link
                  to={strapiData?.buttons[0]?.url}
                  className={styles.btn_white2_border}
                >
                  {strapiData?.buttons[0]?.title}
                </Link>
              )}
            </div>
          </Col>
        </div>
      </Container>
    </div>
  )
}

export default Cta
