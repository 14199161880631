// extracted by mini-css-extract-plugin
export var Challenges = "Challenges-module--Challenges--d6f19";
export var cards = "Challenges-module--cards--46276";
export var description = "Challenges-module--description--37a64";
export var hire = "Challenges-module--hire--3cb29";
export var hireHeading = "Challenges-module--hireHeading--14121";
export var productHeading = "Challenges-module--productHeading--9bd99";
export var subHeading = "Challenges-module--subHeading--40f18";
export var talent = "Challenges-module--talent--49e37";
export var text = "Challenges-module--text--8f2a5";
export var tick = "Challenges-module--tick--2eb8b";