import React from "react"
import { Col } from "react-bootstrap"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"
const Banner = ({ strapiData, globalReader, nymcard, column, freshprep }) => {
  return (
    <div className={`${styles.portfolioAppWorkBanner}`}>
      <Container className={styles.bannerCon}>
        <div
          className={`${styles.fintech} ${
            globalReader ? styles.globalReaderBg : ""
          } ${column ? styles.columnBg : ""} ${
            freshprep ? styles.freshprepBg : ""
          }`}
        >
          <Row className="align-items-center justify-content-center">
            <Col lg={6}>
              <div className={styles.btn}>
                {`${
                  freshprep
                    ? "Freshprep"
                    : column
                    ? "Column"
                    : globalReader
                    ? "Global Reader"
                    : nymcard
                    ? "NymCard"
                    : "Send Your Inquiry"
                }`}
              </div>
              <div className={styles.Heading}>
                <h1
                  className={`${styles.bannerHeading} ${
                    freshprep ? styles.freshprepHeading : ""
                  }`}
                  dangerouslySetInnerHTML={{ __html: strapiData?.title }}
                />
                <p
                  className={styles.subTitle}
                  dangerouslySetInnerHTML={{
                    __html: strapiData?.subTitle,
                  }}
                />
                <hr />
                <p
                  dangerouslySetInnerHTML={{
                    __html: strapiData?.description?.description,
                  }}
                />
              </div>
            </Col>
            <Col lg={6}></Col>
          </Row>
        </div>
        <div className={styles.industry}>
          {strapiData?.cards?.map((e, i) => (
            <div className={styles.idusHead}>
              <p>{e?.title}</p>
              <h3>{e?.subTitle}</h3>
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}

export default Banner
