// extracted by mini-css-extract-plugin
export var Challenges = "Solutions-module--Challenges--e41e6";
export var cards = "Solutions-module--cards--31682";
export var columnSolutionBg = "Solutions-module--columnSolutionBg--3185f";
export var columnSolutionPad = "Solutions-module--columnSolutionPad--5219e";
export var description = "Solutions-module--description--e3bfa";
export var hire = "Solutions-module--hire--db33d";
export var hireHeading = "Solutions-module--hireHeading--0d2f7";
export var talent = "Solutions-module--talent--17023";
export var text = "Solutions-module--text--f54e9";
export var tick = "Solutions-module--tick--0ebf7";