import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Row from "react-bootstrap/Row"
import * as styles from "./Challenges.module.scss"

const Challenges = ({ strapiData, product, columnSolution }) => {
  const image = getImage(strapiData?.secImages[0]?.localFile)

  return (
    <div
      className={`${styles.Challenges} ${
        columnSolution ? styles.columnSolutionPad : ""
      }`}
    >
      <Container>
        <Row className="gap-30 align-items-center">
          <Col
            lg={{ span: 6, order: 1 }}
            md={{ span: 12, order: 1 }}
            xs={{ span: 12, order: 1 }}
          >
            <div className={styles.hire}>
              <h2
                className={`${styles.hireHeading} ${
                  product ? styles.productHeading : ""
                }`}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
              <p
                className={`${styles.subHeading}`}
                dangerouslySetInnerHTML={{ __html: strapiData?.subTitle2 }}
              />
            </div>
            <div className={styles.cards}>
              {strapiData?.cards &&
                strapiData?.cards?.map((item, index) => (
                  <div className={styles.talent} key={index}>
                    <img
                      className={styles.tick}
                      src="https://invozone-backend.s3.us-east-1.amazonaws.com/alert_2_1_cb3fc51c47.png"
                      alt={item?.title || "alert"}
                      decoding="async"
                      loading="lazy"
                    />
                    <div className={styles.text}>
                      <p>{item?.title}</p>
                    </div>
                  </div>
                ))}
            </div>
          </Col>
          <Col
            lg={{ span: 6, order: 2 }}
            md={{ span: 12, order: 2 }}
            xs={{ span: 12, order: 2 }}
          >
            <div className={` text-center ${styles.hire} `}>
              {image ? (
                <GatsbyImage
                  placeholder="blurred"
                  decoding="async"
                  loading="lazy"
                  image={image}
                  alt={strapiData?.title}
                />
              ) : (
                <img
                  src={strapiData?.secImages[0]?.localFile?.publicURL}
                  alt={strapiData?.secImages[0]?.alternativeText || "image"}
                  decoding="async"
                  loading="lazy"
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Challenges
