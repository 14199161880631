// extracted by mini-css-extract-plugin
export var Heading = "Banner-module--Heading--53666";
export var bannerCon = "Banner-module--bannerCon--23167";
export var bannerHeading = "Banner-module--bannerHeading--73415";
export var btn = "Banner-module--btn--f2cf0";
export var columnBg = "Banner-module--columnBg--38417";
export var fintech = "Banner-module--fintech--3818d";
export var freshprepBg = "Banner-module--freshprepBg--eef11";
export var freshprepHeading = "Banner-module--freshprepHeading--41aea";
export var globalReaderBg = "Banner-module--globalReaderBg--41de2";
export var idusHead = "Banner-module--idusHead--550d9";
export var industry = "Banner-module--industry--2238c";
export var portfolioAppWorkBanner = "Banner-module--portfolioAppWorkBanner--e86f0";
export var subTitle = "Banner-module--subTitle--a6838";